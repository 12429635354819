import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Box, Flex, chakra } from "@chakra-ui/react";

import { Carousel } from "react-responsive-carousel";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const Gallery = ({ images = [] }) => {
  return (
    <Carousel centerMode={true} emulateTouch={true} infiniteLoop={true}>
      {images.map((i) => (
        <Box h="full" bg="black">
          <GatsbyImage
            key={i._key}
            image={i.asset.gatsbyImageData}
            style={{ height: "100%" }}
            imgStyle={{ position: "absolute", top: "50%", transform: "translateY(-50%)" }}
          />
        </Box>
      ))}
    </Carousel>
  );
};

export default Gallery;
