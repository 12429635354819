import { Canvas, useFrame } from "@react-three/fiber";
import {
  Center,
  Environment,
  Float,
  OrbitControls,
  PerspectiveCamera,
  PresentationControls,
  Shadow,
  Stage,
  useGLTF,
} from "@react-three/drei";
import React, { Suspense, useRef } from "react";

const Model = ({ src, ...rest }) => {
  const { scene } = useGLTF(src);
  const ref = useRef();
  //   useFrame((state) => {
  //     const t = state.clock.getElapsedTime();
  //     ref.current.rotation.y = t;
  //     // ref.current.position.y = (1 + Math.sin(t / 2)) / 10;
  //   });
  //   ref.current.geometry.translate(1, 0, 0);
  return <primitive object={scene} ref={ref} rotation={[0, 180, 0]} />;
};

const ThreeDViewer = ({ src }) => {
  return (
    <Canvas pixelRatio={[1, 2]} camera={{ position: [-10, 5, 5], fov: 50 }}>
      <ambientLight intensity={1} />
      <Suspense fallback={null}>
        {/* <PerspectiveCamera makeDefault> */}
        {/* <Environment preset="sunset"></Environment> */}
        {/* <Stage
          //   contactShadow
          //   shadows
          adjustCamera
          intensity={1}
          environment="park"
          preset="soft"
          //   controls={controlsRef}
        > */}
        <PresentationControls
          global={false} // Spin globally or by dragging the model
          cursor={true} // Whether to toggle cursor style on drag
          snap={false} // Snap-back to center (can also be a spring config)
          speed={1} // Speed factor
          zoom={1} // Zoom factor when half the polar-max is reached
          rotation={[0, 0, 0]} // Default rotation
          polar={[0, Math.PI / 2]} // Vertical limits
          azimuth={[-Infinity, Infinity]} // Horizontal limits
          config={{ mass: 1, tension: 170, friction: 26 }} // Spring config
        >
          <Float>
            <Center alignTop>
              <Model src={src} />
            </Center>
          </Float>
        </PresentationControls>
        {/* </Stage> */}
        {/* </PerspectiveCamera> */}
      </Suspense>
      {/* <OrbitControls /> */}
    </Canvas>
  );
};

export default ThreeDViewer;
