import "./objects.css";

import { Box, Flex, Heading, SimpleGrid, Text, chakra } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import Gallery from "@components/gallery";
import { GatsbyImage } from "gatsby-plugin-image";
import Map from "@components/map";
import SEO from "@components/seo";
import ThreeDViewer from "@components/threeDViewer";
import Timeline from "@components/timeline";
import { graphql } from "gatsby";
import { motion } from "framer-motion";

const MotionBox = motion(Box);
// const MotionStack = motion(Flex);
// const MotionBadge = motion(Badge);
const MotionHeading = motion(Heading);
const MotionContainer = motion(Flex);
const MotionText = motion(Text);
// const MotionButton = motion(Button);
// const MotionIcon = motion(Icon);

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };

export const query = graphql`
  query getObject($id: String) {
    sanityObjects(id: { eq: $id }) {
      id
      title
      description
      objectExcerpt
      slug {
        current
      }
      history {
        title
        time
        description
      }
      mainImage {
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      eckdaten {
        _key
        title
        content
      }
      lage
      lat
      lng
      mapsModel {
        asset {
          url
        }
      }
      gallery {
        _key
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;

const Objects = (props) => {
  const { data, errors } = props;

  const object = data && data.sanityObjects;
  console.log(object);

  const isBrowser = typeof window !== undefined;

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (isBrowser) {
      setTimeout(() => {
        setIsMounted(true);
      }, 500);
    }
    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <>
      <SEO title={object.title} description={object.objectExcerpt} />
      <MotionContainer
        minH="90vh"
        align="center"
        justify="center"
        color="white"
        bg="transparent"
        zIndex={-2}
        animate={{ backgroundColor: "var(--chakra-colors-primary)" }}
        transition={transition}
        borderBottom="1px solid black"
        pt={{ md: "20" }}
      >
        <SimpleGrid
          columns={{ base: 1, xl: 2 }}
          // px={{ xl: "32" }}
          gap={{ xl: "8" }}
          py={{ base: "8" }}
          w={{ lg: "full" }}
        >
          <Flex direction="column" justify="center" gridRow={{ base: 2, lg: 1 }}>
            <Box maxW="container.lg" p={{ base: "8" }} pt={{ base: "0", lg: "8" }}>
              <MotionHeading
                mb="4"
                fontSize={{ base: "4xl", lg: "7xl" }}
                opacity={0}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6, ...transition }}
              >
                {object.title}
              </MotionHeading>
              <MotionText
                opacity={0}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.8, ...transition }}
              >
                {object.description}
              </MotionText>
            </Box>
          </Flex>
          <Box position="relative" p="8">
            <MotionBox
              position="relative"
              border="1px solid black"
              mr={{ base: "16px", lg: "30px" }}
              mb={{ base: "16px", lg: "30px" }}
              layoutId={object.slug.current}
              transition={transition}
              _after={{
                content: "''",
                bg: "white",
                position: "absolute",
                top: "0",
                left: "0",
                width: "full",
                height: "full",
                zIndex: 10,
                border: "1px solid black",
                transform: {
                  base: isMounted ? "translate(16px,16px)" : "translate(0px, 0px)",
                  lg: isMounted ? "translate(30px,30px)" : "translate(0px, 0px)",
                },
                transition: "all 0.5s ease-out",
              }}
            >
              <GatsbyImage image={object.mainImage.asset.gatsbyImageData} style={{ zIndex: 20 }} />
            </MotionBox>
          </Box>
        </SimpleGrid>
      </MotionContainer>
      <Box>
        <Heading
          fontSize={{ base: "4xl", lg: "6xl" }}
          textAlign="center"
          py="8"
          borderBottom="1px solid black"
        >
          Das Objekt
        </Heading>
      </Box>
      <SimpleGrid
        columns={{ base: 1, xl: 2 }}
        minH={{ lg: "4xl" }}
        gap="12"
        gridTemplateColumns={{ md: "40% 60%" }}
        borderBottom="1px solid black"
        pb={{ base: "8" }}
      >
        <Flex
          direction="column"
          justify="center"
          w={{ base: "full", lg: "lg" }}
          // justifySelf={{ xl: "self-end" }}
          gridRow={{ base: 1, lg: 1 }}
          px={{ base: "8" }}
          py={{ base: "12" }}
        >
          <Heading mb="2">Eckdaten</Heading>
          {object.eckdaten.map((d) => (
            <Text key={d._key} fontWeight="bold">
              {d.title}: <chakra.span fontWeight="normal">{d.content}</chakra.span>
            </Text>
          ))}
        </Flex>
        <Box minH={{ base: "sm" }} maxH="4xl">
          {object.mapsModel && <ThreeDViewer src={object.mapsModel.asset.url} />}
        </Box>
      </SimpleGrid>
      <Box>
        <Heading
          fontSize={{ base: "4xl", lg: "6xl" }}
          textAlign="center"
          py="8"
          borderBottom="1px solid black"
        >
          Lage
        </Heading>
      </Box>
      <SimpleGrid columns={{ base: 1, lg: 2 }} minH={{ lg: "xl" }} borderBottom="1px solid black">
        <Flex
          direction="column"
          justify="center"
          px={{ base: "8" }}
          py={{ base: "12" }}
          borderRight={{ lg: "1px solid black" }}
          borderBottom={{ base: "1px solid black", lg: "none" }}
        >
          <Text>{object.lage}</Text>
        </Flex>
        <Box minH="sm">
          {isBrowser && (
            // <Suspense placeholder={<MapPlaceholder />}>
            <Map lat={parseFloat(object.lat)} lng={parseFloat(object.lng)} zoom={15} />
            // </Suspense>
          )}
        </Box>
      </SimpleGrid>
      <Box>
        <Heading
          fontSize={{ base: "4xl", lg: "6xl" }}
          textAlign="center"
          py="8"
          borderBottom="1px solid black"
        >
          Historie
        </Heading>
      </Box>
      <Box px={{ base: "8" }} borderBottom="1px solid black">
        <Timeline history={object.history} />
      </Box>
      <Gallery images={object.gallery} />
    </>
  );
};

export default Objects;
